import { Component } from 'vue-property-decorator';
import { loginHelper, meService, teamHelper, ecosystemHelper } from '@/main';
import to from 'await-to-js';
import PageRender from '@/models/PageRender';
import { profileModule } from '@/store/modules/profile';
import { periodModule } from '@/store/modules/period';

@Component
export default class HomeComponent extends PageRender {
    public async created() {
        if (profileModule.isLoggedIn) {
            const redirectUriStorage = window.localStorage;
            if (
                !profileModule.brightProfile ||
                (profileModule.brightProfile && profileModule.brightProfile.personId === 0) ||
                this.$route.query.reloadProfile
            ) {
                const [err, reponse] = await to(meService.profile());
                if (err) {
                    this.showError('Failed to load profile');
                    return loginHelper.logOut();
                }
                profileModule.setBrightProfile(reponse);

                await ecosystemHelper.setup();
                await teamHelper.setup();
            }

            if (!periodModule.periods || (periodModule.periods && periodModule.periods.length === 0)) {
                await periodModule.init();

                if (!periodModule.periods || (periodModule.periods && periodModule.periods.length === 0)) {
                    return;
                }
            }
            const returnUrl = redirectUriStorage.getItem('redirectUri');
            if (returnUrl) {
                redirectUriStorage.removeItem('redirectUri');
            }

            const url = returnUrl !== null ? returnUrl.toString() : '';
            if (url === '/' || url === '' || !url) {
                return this.$router.push({
                    name: 'me-finances',
                    params: {
                        ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                        ecosystemKey: ecosystemHelper.currentEcosystem.key,
                        teamId: teamHelper.currentTeam.id.toString(),
                        teamKey: teamHelper.currentTeam.key,
                        period: periodModule.selectedPeriod,
                        tab: 'overview',
                    },
                });
            }

            const route = this.$router.resolve(url);
            if (route && route.resolved) {
                const teamId = route.resolved.params.teamId;
                const teamKey = route.resolved.params.teamKey;
                const ecosystemKey = route.resolved.params.ecosystemKey;
                const ecosystemId = route.resolved.params.ecosystemId;
                route.route.params.teamId = teamId;
                route.route.params.teamKey = teamKey;
                route.route.params.ecosystemKey = ecosystemKey;
                route.route.params.ecosystemId = ecosystemId;
                teamHelper.setTeamByTeamId(parseInt(teamId));
                ecosystemHelper.setEcosystemByEcosystemId(parseInt(ecosystemId));
            }

            if (!route) {
                return this.$router.push({ name: 'app-error' });
            }

            return this.$router.push({ name: route.route.name, params: route.route.params, query: route.route.query });
        }
    }
}
